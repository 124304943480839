export const app = {
    audio: undefined,
    browser: undefined,
    components: undefined,
    current: undefined,
    cursor: undefined,
    device: undefined,
    engine: undefined,
    footer: undefined,
    init: undefined,
    loading: true,
    loader: undefined,
    mode: undefined,
    time: undefined,
    theme: undefined,
    nav: undefined,
    os: undefined,
    progressiveimg: undefined,
    renderer: undefined,
    ratio: undefined,
    root: undefined,
    scroll: undefined,
    transition: undefined,
    viewport: undefined,
    virtualscroll: false,
    wrapper: undefined,
    webp: undefined
};

export const minicart = {
    url: `${window.location.origin}/wp-content/themes/filatelia3fil/minicart.php`,
};


export const colors = {
    white: '#FFF8F5',
    black: '#202020',
    primary: '#53F5CC'
}

export const menu = {
    isOpen: false,
    current: undefined
}

export const modal = {
    isOpen: false,
    current: undefined
}

export const breakpoints = {
    Desktop: 1900,
	Laptop: 1200,
	iPadLandscape: 1024,
	iPadPortrait: 768,
	iPhoneProMax: 428,
    iPhonePlus: 414,
    iPhoneSE: 320,
	iPhone: 375,
};

export const operators = {
    '<=' : 'max-width:',
    '>=' : 'min-width:',
    '≥' : 'min-width:',
    '≤' : 'max-width:', 
    '>' : 'min-width:', 
    '<' : 'max-width:',
}