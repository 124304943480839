// App Store
import { app } from './utils/store'

import 'vanilla-cookieconsent/src/cookieconsent'

class CookieConsent {
    constructor(){
        // obtain plugin
        this.consent = initCookieConsent()

        // run plugin with your configuration
        this.consent.run({
            current_lang: 'it',
            autoclear_cookies: true,                   // default: false
            page_scripts: true,                        // default: false
            gui_options: {
                consent_modal: {
                    layout: 'cloud',               // box/cloud/bar
                    position: 'bottom center',     // bottom/middle/top + left/right/center
                    transition: 'slide',           // zoom/slide
                    swap_buttons: true            // enable to invert buttons
                },
                settings_modal: {
                    layout: 'box',                 // box/bar
                    position: 'right',           // left/right
                    transition: 'zoom'            // zoom/slide
                }
            },

            // mode: 'opt-in'                          // default: 'opt-in' value: 'opt-in' or 'opt-out'
            // delay: 0,                               // default: 0
            // auto_language: null                     // default: null could also be 'browser' or 'document'
            // autorun: true,                          // default: true
            // force_consent: false,                   // default: false
            // hide_from_bots: true,                   // default: true
            // remove_cookie_tables: false             // default: false
            cookie_name: 'cookie_consent',               // default: 'cc_cookie'
            // cookie_expiration: 182,                 // default: 182 (days)
            // cookie_necessary_only_expiration: 182   // default: disabled
            // cookie_domain: location.hostname,       // default: current domain
            // cookie_path: '/',                       // default: root
            // cookie_same_site: 'Lax',                // default: 'Lax'
            // use_rfc_cookie: false,                  // default: false
            // revision: 0,                            // default: 0

            onFirstAction: function(user_preferences, cookie){
                // callback triggered only once
            },

            onAccept: function (cookie) {
                // ...
            },

            onChange: function (cookie, changed_preferences) {
                // ...
            },
            languages: {
                'it': {
                    consent_modal: {
                        title: 'Cookies',
                        description: 'Questo sito web utilizza cookie essenziali per garantirne il corretto funzionamento e cookie di monitoraggio. <button type="button" data-cc="c-settings" class="cc-link">Gestisci</button>',
                        primary_btn: {
                            text: 'Accetta tutti',
                            role: 'accept_all'              // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Solo necessari',
                            role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                        }
                    },
                    settings_modal: {
                        title: 'Cookie — Preferenze',
                        save_settings_btn: 'Salva',
                        accept_all_btn: 'Accetta tutti',
                        reject_all_btn: 'Rifiuta tutti',
                        close_btn_label: 'Chiudi',
                        cookie_table_headers: [
                            {col1: 'Nome'},
                            {col2: 'Dominio'},
                            {col3: 'Scadenza'},
                            {col4: 'Descrizione'}
                        ],
                        blocks: [
                            {
                                title: false,
                                description: `Utilizzo i cookie per garantire le funzionalità di base del sito e per migliorare la vostra esperienza online. Per ogni categoria è possibile scegliere l’opt-in/out ogni volta che lo si desidera. Per ulteriori dettagli relativi ai cookie e ad altri dati sensibili, si prega di leggere l’informativa sulla <a href="${window.location.origin}/privacy-policy" class="cc-link">privacy policy</a>.`
                            }, {
                                title: 'Strettamente necessari',
                                description: 'Questi cookie sono essenziali per il corretto funzionamento del mio sito web. Senza questi cookie, il sito web non funzionerebbe correttamente',
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                                },
                                cookie_table: [
                                    {
                                        col1: 'wc_fragments_***',
                                        col2: 'filateliatreviso.com',
                                        col3: 'Sessione',
                                        col4: 'Questo cookie mantiene il carrello aggiornato con i prodotti inseriti.'
                                    },{
                                        col1: '_grecaptcha',
                                        col2: 'google.com',
                                        col3: 'Persistente',
                                        col4: 'Necessario per il funzionamento del captcha. Memorizza un codice per impedire la rappresentazione dell’utente.'
                                    }
                                ]
                            }, {
                                title: 'Performance e Analisi',
                                description: 'Questi cookie consentono al sito web di ricordare le scelte effettuate dall’utente in passato e servono per analizzare e migliorare l’esperienza utente',
                                toggle: {
                                    value: 'analytics',     // your cookie category
                                    enabled: false,
                                    readonly: false
                                },
                                cookie_table: [             // list of all expected cookies
                                    {
                                        col1: '^_ga',       // match all cookies starting with "_ga"
                                        col2: 'google.com',
                                        col3: '2 anni',
                                        col4: 'Registra un ID univoco per un visitatore del sito Web e tiene traccia di come il visitatore utilizza il sito Web. I dati vengono utilizzati per le statistiche.',
                                        is_regex: true
                                    },
                                    {
                                        col1: '_hjSession_***',
                                        col2: 'hotjar.com',
                                        col3: '30 min',
                                        col4: 'Assicura che le richieste successive nella finestra di sessione siano attribuite alla stessa sessione.'
                                    },
                                    {
                                        col1: '_hjSessionUser_***',
                                        col2: 'hotjar.com',
                                        col3: '1 anno',
                                        col4: 'Imposta un ID al sito Web quando un utente arriva per la prima volta su una pagina.'
                                    },
                                    {
                                        col1: '_hjFirstSeen',
                                        col2: 'hotjar.com',
                                        col3: '30 min',
                                        col4: 'Identifica la prima sessione di un nuovo utente.',
                                    },
                                    {
                                        col1: '_hjAbsoluteSessionInProgress',
                                        col2:'hotjar.com',
                                        col3: '30 min',
                                        col4: 'Utilizzato per rilevare la prima sessione di pageview di un utente.'
                                    }
                                ]
                            }, {
                                title: 'informazioni aggiuntive',
                                description: `Per qualsiasi domanda relativa alla nostra politica sui cookie e alle vostre scelte, vi preghiamo di contattarci.`,
                            }
                        ]
                    }
                }
            }
        })

        window.onload = () => { document.getElementById('s-bl').setAttribute('data-lenis-prevent',true) }
    }
}

new CookieConsent()
